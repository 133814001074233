@import "vars.scss";
.scale-up-ver-top {
	-webkit-animation: scale-up-ver-top 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) forwards;
	        animation: scale-up-ver-top 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) forwards;
}

 @-webkit-keyframes scale-up-ver-top{
    0%{
        height: $pokerWidth / 4;
    }
    100%{
        height: $pokerWidth / 2;
    }
}
@keyframes scale-up-ver-top{
    0%{
        height: $pokerWidth / 4;
    }
    100%{
        height: $pokerWidth / 2;
    }
}
.drip{
  .drep{
    animation: scale-up-ver-top 3s ease-in-out infinite;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left:0; right: 0; top: 0;
    animation-direction: alternate;
    z-index: 100;
    background-color: $black;
    border-radius: 0 0 5rem 5rem;
    height: $pokerWidth /2;
    width: 3rem;
  }

  .drop{
    width: 4rem;
    height: 4rem;
    top: -20%;
    background-color: $black;
    position: absolute;
    border-radius:50%;
    animation: fall 7s infinite;
    border-radius: 0 50% 50% 50%;
    /*border: 3px solid black;*/
    transform: rotate(45deg);
    margin-top: 20px;
  }
  .drop:nth-of-type(2n){
    width: 5rem;
    height: 5rem;
  }
  .drop:nth-of-type(3n){
    width: 6rem;
    height: 6rem;
  }
  .drop:nth-of-type(1n){
    left: 10%;
    animation-delay: 6.5s;
  }
  .drop:nth-of-type(2n){
    left: 30%;
    animation-delay: 3.3s;
  }
  .drop:nth-of-type(3n){
    left: 50%;
    animation-delay: 1.5s;
  }
  .drop:nth-of-type(4n){
    left: 40%;
    animation-delay: 0.9s;
  }
  .drop:nth-of-type(5n){
    left: 20%;
    animation-delay: 0.6s;
  }
  .drop:nth-of-type(6n){
    left: 30%;
    animation-delay: 3.5s;
  }
  .drop:nth-of-type(7n){
    left: 40%;
    animation-delay: 6.0s;
  }
  .drop:nth-of-type(8n){
    left: 50%;
    animation-delay: 1.8s;
  }
  .drop:nth-of-type(9n){
    left: 10%;
    animation-delay: 3.8s;
  }
  .drop:nth-of-type(10n){
    left: 20%;
    animation-delay: 2.9s;
  }
  .drop:nth-of-type(11n){
    left: 40%;
    animation-delay: 6.4s;
  }
  .drop:nth-of-type(12n){
    left: 10%;
    animation-delay: 1.7s;
  }
  .drop:nth-of-type(13n){
    left: 20%;
    animation-delay: 5.5s;
  }
  .drop:nth-of-type(14n){
    left: 50%;
    animation-delay: 0.5s;
  }
  .drop:nth-of-type(15n){
    left: 30%;
    animation-delay: 6.8s;
  }
  .drop:nth-of-type(16n){
    left: 10%;
    animation-delay: 2.7s;
  }
  .drop:nth-of-type(17n){
    left: 20%;
    animation-delay: 2.2s;
  }
  .border{
    position: absolute;
    background-color: $white;
  }
  .border:nth-last-of-type(1){
    top: 0; left:0;
  }
  .border:nth-last-of-type(2){
    bottom: 0; left:0;
  }
}

.jumbotron .wave{
  position: absolute;
  left: 0;
  width: 100%; 
  height: 25rem;
  background: url(../ass/svg/wave.svg);
  background-size: 100vw 80vh;
}

.wave.wave1{
  animation: wave 5s linear infinite;
  bottom: -4rem;
  opacity: 100%;
  z-index: 10;
  animation-delay: 0s;
}
.wave.wave2{
  animation: wave-negative 8s linear infinite;
  bottom: 0rem;
  opacity: 80%;
  z-index: 9;
  animation-delay: -3s;
}
.wave.wave3{
  animation: wave 12s linear infinite;
  bottom: 4rem;
  opacity: 60%;
  z-index: 8;
  animation-delay: -2s;
}
.wave.wave4{
  animation: wave-negative 15s linear infinite;
  bottom: 8rem;
  opacity: 40%;
  z-index: 7;
  animation-delay: -5s;
}
@keyframes wave{
  0%{
    background-position-x: 0rem;
  }
  100%{
    background-position-x: 100rem;
  }
}

@keyframes wave-negative{
  0%{
    background-position-x: 0rem;
  }
  100%{
    background-position-x: -100rem;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fall{
  0% {top: -20%}
  20% {top: 0%}
  80% {top: 85%}
  100% {top: 100%}
}
