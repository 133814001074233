@import "vars.scss";

@font-face {
  font-family: moist;
  src: url(../ass/font/MOIST.ttf);
}
html {
  overflow-x: hidden; 
  height: 100%;
  body {
    overflow-x: hidden; 
    min-height: 100%;
    background-color: $primary;
  }
}
.App {
  text-align: center;
}

.hidden {
  display: none;
}


.App-header {
  background-color: $primary;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $secondary;
}


.invert {
    filter: invert(100%);
}
/*
.invert img {
    filter: invert(100%);
}
*/
.App {
  background-color: $primary;
  color: $secondary;

  .navbar{
    background: $dark-gray;
    .navbar-brand {
      font-family: moist;
      font-size: 1.5rem;
    }
    a {
      color: $secondary;
    }
    .active{
      font-weight: bold;
    }
  }
  .footer{
    min-height: 100%;
    background: $dark-gray;
    a {
      color: $accent;
    }
  }
  .jumbotron{
    position: relative;
    min-height: 100vh;
    margin-bottom: 0;
    background: $middle-gray;
    color: $white;
    overflow: hidden;
    padding: 0;
    #video{
      z-index: 0;
      position: absolute;
      left: 0;
      width: 100%; 
      height: 105vh;
      object-fit: cover;

    }
    .display{
      padding: 1rem;
      z-index: 10;
      position: absolute;
      left: 0; right: 0;
      margin-left: auto;
      margin-right: auto;
      h1{
        position: absolute;
        left: 0; right: 0; top: 20rem;
        margin-left: auto;
        margin-right: auto;
        font-family: moist;
        font-size: 2rem;
      }
      .App-logo {
        height: $pokerWidth;
      }
      .drip{
        filter: url(#goo);
        position: absolute;
        left:0; right: 0; top: 21.5rem;
        height: 60%;
        overflow: hidden;
        width: $pokerWidth - 2rem;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  hr{
    color: $secondary;
    background-color: $secondary;
    margin: 2rem;
  }

  .hand{
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    .square{
      width: $pokerHeight;
      height: $pokerHeight;
      max-width: $pokerHeight;
      max-height: $pokerHeight;
      min-width: $pokerHeight;
      min-height: $pokerHeight;
      border-radius: 1rem;
      padding: 0;
      margin: 1rem;
    }
  }

  div {
    .poker {
      width: $pokerWidth;
      max-width: $pokerWidth;
      height: $pokerHeight;
    }
    .flip-card{
      transition: transform 0.8s;
      transform-style: preserve-3d;
      //cursor: pointer;
      position: relative;
      overflow: visible !important;
    }
    .flip-card.is-flipped {
      transform: rotateY(180deg);
    }      
    .card{
      height: $pokerHeight;
      min-width: $pokerWidth;
      padding: 0;
      border-radius: 1rem;
      margin: 1rem;
      box-shadow: 0rem .2rem $primaryShadow;
      text-align: left;
      margin: 1rem;
      overflow: hidden;
      background: transparent;
      .card-face{
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
      }
      .card-front{
        .card-img {
          border-radius: 1rem;
          height: 100%;
          object-fit: cover; 

        }
      }
      .card-back{
        transform: rotateY(180deg);
      }
      .title{
        color: $secondary;
        padding: .3rem;
        display:inline-block;
        box-shadow: 0rem .2rem $primaryShadow;
        top: 1.6rem; left: 1.6rem; 
        position:absolute;
        background-color: $primary;
        border-radius: .5rem;
        margin: 0;
        margin-right: 5rem;
        backface-visibility: hidden;
      }
      .text{
        color: $secondary;
        text-align: right;
        bottom: .8rem; right: 1.6rem; 
        position:absolute;
        padding: .4rem;
        box-shadow: 0rem .2rem $primaryShadow;
        background-color: $primary;
        border-radius: .5rem;
        margin-left: 5rem;
        backface-visibility: hidden;
      }
      .lvl{
        background-color: $primary;
        box-shadow: 0rem .2rem $primaryShadow;
        border-radius: .5rem;
        padding: .3rem;
        margin: 0;
        color: $secondary;
        text-align: right;
        align: right;
        bottom: 1.6rem; right: 1.6rem; 
        position:absolute;
        backface-visibility: hidden;
      }
      .lock{
        margin: auto;
        top: 0; bottom: 0; left: 0; right: 0;
        height: 8rem;
        position:absolute;
      }
      .soldOut {
        background-color: $primary;
        box-shadow: 0rem .2rem $primaryShadow;
        text-align: center;
        color: $secondary;
        transform: rotateY(0deg) rotate(45deg);
        position: absolute;
        width: 20rem;
        top: 3rem;
        right: -6rem;
        backface-visibility: hidden;
      }
    }
  }
  .section.comics {
    min-height: 100vh;
  }
  .section.store {
    min-height: 100vh;
  }
  .section {
    padding: 1rem;
    .container {
      margin-left: 1rem;
      margin-right: 1rem;
      padding-left: 0;
    }
    .box {
      display:inline;
      padding: .3rem;
      border-style: solid;
      border-radius: .3rem;
      float: left;
      margin-left: 1rem;
      font-family: moist;
    }
    h4 {
      display:inline;
      margin: .5rem;
      margin-top: 1rem;
    }
  }
}


.flex-bio {
  min-height: 100vh;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;

  .member{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    .collapse{
      display: none;
    }
  }
  .member.active{
    .collapse{
      display: inline;
    }
  }

  .stats p {
    margin: 1rem;
  }
  
  .progress {
    margin: .5rem;
    order: 2;
    background-color: $black;
    line-height: normal; /** sigh... **/
  
    .progress-bar{
      color: $white;
      font-weight: bold;
      text-align: left;
      padding: .5rem;
    }
  }
}

.flex-bio > * {
  flex: 1 100%;
  align-items: flex-start;
}

.stat-row {
  flex-flow: row wrap;
  display: flex;
}
.stat-row > * {
  flex: 1 100%;
}
/* Medium screens */
@media all and (min-width: $pokerWidth * 2.5) {
  /* We tell both sidebars to share a row */
  .flex-bio { 
    flex-wrap:  wrap; 
    .member {
      flex: 0 50%;
    }
  }
  .stat-row { flex-wrap: nowrap; }
  .card { flex: 1 auto; }
  .stats{ flex: 1 auto; }
}

/* Large screens */
@media all and (min-width: $pokerWidth * 5) {
  /* We invert order of first sidebar and main
   * And tell the main element to take twice as much width as the other two sidebars 
   */
  .stats    { flex: 2 auto;  }
  .flex-bio { flex-wrap:  nowrap; }
}

.flex-container {
  /* We first create a flex layout context */
  display: flex;
  
  /* Then we define the flow direction 
     and if we allow the items to wrap 
   * Remember this is the same as:
   * flex-direction: row;
   * flex-wrap: wrap;
   */
  flex-flow: row wrap;
  
  /* Then we define how is distributed the remaining space */
  justify-content: space-around;
}

